import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';

import {
    AvailableAssistants,
    AvailableAssistantsUser,
    CaptureMode,
    Machine,
    SetAssistantsEntry,
} from '@data-terminal/shared-models';

import { ManageAssistantCardComponent } from '../../components/manage-assistant-card/manage-assistant-card.component';
import {
    HdmuiEmptyStatesComponent,
    HdmuiEmptyStatesDescriptionDirective,
    HdmuiEmptyStatesTitleDirective,
} from '@heidelberg/hdmui-angular';
import { MatDivider } from '@angular/material/divider';

export interface ManageAssistantsDialogData {
    workstations: Machine[];
    userId: string;
    availableAssistants: AvailableAssistants[];
}

@Component({
    standalone: true,
    templateUrl: './manage-assistants-dialog.component.html',
    styleUrls: ['./manage-assistants-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatToolbarModule,
        TranslateModule,
        MatButtonModule,
        ManageAssistantCardComponent,
        HdmuiEmptyStatesComponent,
        HdmuiEmptyStatesDescriptionDirective,
        HdmuiEmptyStatesTitleDirective,
        MatDivider,
    ],
})
export class ManageAssistantsDialogComponent {
    readonly #dialogRef = inject(MatDialogRef<ManageAssistantsDialogComponent>);
    readonly #data = inject(MAT_DIALOG_DATA);

    public userId!: string;
    public workstations: Machine[] = [];
    public allAvailableAssistants: AvailableAssistants[] = [];
    public workstationAssistantsMap = new Map<string, string[]>();

    constructor() {
        this.workstations = this.#data.workstations.filter(
            (workstation: Machine) => workstation.captureMode !== CaptureMode.TIME
        );
        this.userId = this.#data.userId;
        this.allAvailableAssistants = this.#data.availableAssistants;
    }

    public onDiscardClick(): void {
        this.#dialogRef.close();
    }

    public onApplyClick(): void {
        const setAssistantsEntries: SetAssistantsEntry[] = [...this.workstationAssistantsMap.entries()].map(
            ([machineId, userIds]) => ({
                machineId,
                userIds,
            })
        );
        this.#dialogRef.close(setAssistantsEntries);
    }

    public getAvailableEmployees(workstation: Machine): AvailableAssistantsUser[] {
        return (
            this.allAvailableAssistants
                .find(({ machineId }) => machineId === workstation.machineId)
                ?.users.filter(({ userId }) => userId !== this.userId) || []
        );
    }

    public onSelectedEmployeesChange(employees: AvailableAssistantsUser[], workstationId: string): void {
        this.workstationAssistantsMap.set(
            workstationId,
            employees.map(({ userId }) => userId)
        );
    }
}
