<mat-toolbar class="footer__container">
    <button mat-icon-button (click)="onLeaveButtonClick()">
        <mat-icon role="img" svgIcon="hdmui:exit"></mat-icon>
    </button>
    <mat-divider></mat-divider>
    @if (user) {
        <div class="footer__data">
            <p class="hdmui-overline hdmui-m-0 hdmui-p-0">{{ user.userId }}</p>
            <div class="footer__data--second-line">
                @if (machineSignInTimer$ | async; as timer) {
                    <p class="hdmui-caption hdmui-p-0">
                        {{
                            'DC.LOGGED_IN_SINCE'
                                | translate: { time: timer | timeFormatter: true : true : true : false }
                        }}
                    </p>
                    <span>-</span>
                }
                @if (user.userMachines && user.userMachines.length > 0) {
                    <p class="hdmui-caption hdmui-p-0">
                        {{ 'DC.FOOTER.MACHINE_INFO' | translate: { machine: user.userMachines.length } }}
                    </p>
                }
                @if ((inactiveWorkstations$ | async)?.length) {
                    <span>-</span>
                    <p class="footer__inactive-warning hdmui-caption hdmui-p-0 mat-mdc-form-field-error">
                        {{ 'DC.FOOTER.NO_ACTIVITY' | translate }}
                    </p>
                }
            </div>
        </div>
    }
    <div class="footer__timecounter">
        <span>
            @if (displayFooterTime) {
                <p class="footer__timecounter__value mat-mdc-form-field-error hdmui-caption hdmui-ms-3 hdmui-p-0">
                    {{ (inactivityTimer$ | async) || 0 | timeFormatter }}
                </p>
            }
        </span>
    </div>
</mat-toolbar>
