@if (subNavLinks$ | async; as links) {
    @if (isSecondLevel$ | async; as isSecondLevel) {
        @switch (customTab$ | async) {
            @case ('OPERATION-TAB') {
                <app-operation-navigation-tab
                    [links]="links"
                    [isSecondLevel]="isSecondLevel"
                    [tabPanel]="tabPanel"
                ></app-operation-navigation-tab>
            }
            @case ('STANDARD') {
                <app-standard-navigation-tab
                    [links]="links"
                    [isSecondLevel]="isSecondLevel"
                    [tabPanel]="tabPanel"
                ></app-standard-navigation-tab>
            }
        }
    }
}
