import { ChangeDetectionStrategy, Component, Input, NgModule, OnInit } from '@angular/core';
import { MatTabNavPanel, MatTabsModule } from '@angular/material/tabs';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatBadgeModule } from '@angular/material/badge';
import { Observable, share } from 'rxjs';

import { NavLink } from '@data-terminal/shared-models';
import { getNavLinks, isCustomTab, isSecondLevel, NavigationService } from '@data-terminal/data-access';

import { StandardNavigationTabComponent } from './standard-navigation-tab/standard-navigation-tab.component';
import { OperationNavigationTabComponent } from './operation-navigation-tab/operation-navigation-tab.component';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent implements OnInit {
    @Input() tabPanel!: MatTabNavPanel;

    public subNavLinks$?: Observable<NavLink[]>;
    public isSecondLevel$?: Observable<boolean>;
    public customTab$?: Observable<string | undefined>;

    constructor(private readonly navigationService: NavigationService) {}

    public ngOnInit(): void {
        this.subNavLinks$ = this.navigationService.navData$.pipe(getNavLinks);
        this.customTab$ = this.navigationService.navData$.pipe(isCustomTab);
        this.isSecondLevel$ = this.navigationService.navData$.pipe(isSecondLevel, share());
    }
}

@NgModule({
    declarations: [NavigationComponent, StandardNavigationTabComponent, OperationNavigationTabComponent],
    exports: [NavigationComponent],
    imports: [MatTabsModule, CommonModule, RouterModule, TranslateModule, MatBadgeModule],
})
export class NavigationComponentModule {}
