import { Component, Input } from '@angular/core';
import { MatTabNavPanel } from '@angular/material/tabs';

import { NavLink } from '@data-terminal/shared-models';

@Component({
    selector: 'app-standard-navigation-tab',
    templateUrl: './standard-navigation-tab.component.html',
    styleUrls: ['./standard-navigation-tab.component.scss'],
})
export class StandardNavigationTabComponent {
    @Input() tabPanel!: MatTabNavPanel;
    @Input() links!: NavLink[];
    @Input() isSecondLevel!: boolean;
}
