import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';

import { AccessControlService } from './access-control.service';
import { AdminAccessControlService } from './admin-access-control.service';

export const activateForCloudAdminOrMember: CanActivateFn = (route: ActivatedRouteSnapshot) => {
    if (inject(AdminAccessControlService).canActivate()) {
        return true;
    }
    return inject(AccessControlService).canActivate(route);
};
