import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { CCAuthService } from '@heidelberg/control-center-frontend-integration/auth';

import { CCRoleId } from '@data-terminal/shared-models';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class AdminAccessControlService {
    constructor(private readonly ccAuthService: CCAuthService) {}

    canActivate(): boolean {
        return !!this.ccAuthService.getCurrentMembership()?.ccRoles?.find((role) => role === CCRoleId.adm);
    }
}
