import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TRANSLATION_VERSION } from '../generated/translation_version';
import { ENGLISH_LOCALE, ENGLISH_US_LOCALE } from './locale.const';

export class CustomTranslateHttpLoader implements TranslateLoader {
    constructor(private readonly http: HttpClient) {}

    /**
     * Gets the translations from the server
     */
    public getTranslation(lang: string): Observable<object> {
        return this.http.get(
            `./assets/generated/i18n/${TRANSLATION_VERSION}/dataterminal_${lang === ENGLISH_US_LOCALE ? ENGLISH_LOCALE : lang}.json`
        );
    }
}
