<div class="hdmui hdmui-m-0 manage-assistants hdmui-container">
    <header>
        <mat-toolbar class="manage-assistants__header">
            <h2>{{ 'DC.MANAGE_ASSISTANTS.DIALOG_TITLE' | translate }}</h2>
        </mat-toolbar>
    </header>

    <section class="hdmui-content manage-assistants__content">
        @if (!!workstations.length) {
            <p>
                {{ 'DC.MANAGE_ASSISTANTS.DIALOG_DESCRIPTION' | translate }}
            </p>
            <div class="manage-assistants__cards">
                @for (workstation of workstations; track workstation) {
                    <data-terminal-manage-assistant-card
                        [workstation]="workstation"
                        [availableEmployees]="getAvailableEmployees(workstation)"
                        (selectedEmployeesChange)="onSelectedEmployeesChange($event, workstation.machineId)"
                    >
                    </data-terminal-manage-assistant-card>
                }
            </div>
        } @else {
            <hdmui-empty-states icon="hdmui:exclamationMarkCircled">
                <hdmui-empty-states-title>
                    {{ 'DC.MANAGE_ASSISTANTS.EMPTY.TITLE' | translate }}
                </hdmui-empty-states-title>
                <hdmui-empty-states-description>
                    {{ 'DC.MANAGE_ASSISTANTS.EMPTY.DESCRIPTION' | translate }}
                </hdmui-empty-states-description>
            </hdmui-empty-states>
        }
    </section>

    <mat-divider></mat-divider>

    <footer>
        <button mat-stroked-button (click)="onDiscardClick()">
            {{ '*.action.cancel' | translate }}
        </button>
        <button mat-flat-button (click)="onApplyClick()" [disabled]="!workstations.length">
            {{ '*.apply' | translate }}
        </button>
    </footer>
</div>
