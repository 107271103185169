<nav
    class="hdmui-subnav navigation"
    mat-tab-nav-bar
    mat-stretch-tabs="false"
    [tabPanel]="tabPanel"
    [ngClass]="{ 'hdmui-background-1': isSecondLevel }"
>
    @if (operationTabsConfig$ | async; as config) {
        @for (link of links; track link) {
            <a
                mat-tab-link
                [routerLink]="isLinkDisabled(link, config) ? null : link.path"
                [active]="rla.isActive"
                routerLinkActive
                #rla="routerLinkActive"
                [disabled]="isLinkDisabled(link, config)"
            >
                @if (link.id && config[link.id] && config[link.id].badge !== 0) {
                    <span class="hdmui-badge-highlight" [matBadge]="config[link.id].badge">
                        {{ link.title | translate }}
                    </span>
                } @else {
                    {{ link.title | translate }}
                }
            </a>
        }
    }
</nav>
