import { Routes } from '@angular/router';

import { AccessControlService } from '@data-terminal/data-access';
import { ROUTE_PARAMS, ROUTE_PATH, RouteData } from '@data-terminal/shared-models';
import { activateForCloudAdminOrMember } from '../../projects/data-access/src/lib/route-guards/access-control/master-access-control';
const DC_APP_TITLE = 'DC.APP.TITLE';

export const ROUTES: Routes = [
    {
        path: `${ROUTE_PATH.error}`,
        loadChildren: () => import('./pages/error-page/error-page.component'),
    },
    {
        path: `${ROUTE_PATH.userNotFound}`,
        loadChildren: () => import('./pages/user-not-found-page/user-not-found-page.component'),
    },
    {
        path: `:${ROUTE_PARAMS.orgId}`,
        redirectTo: `:${ROUTE_PARAMS.orgId}/${ROUTE_PATH.dashboard}`,
        pathMatch: 'full',
    },
    {
        path: `:${ROUTE_PARAMS.orgId}/`,
        redirectTo: `:${ROUTE_PARAMS.orgId}/${ROUTE_PATH.dashboard}`,
        pathMatch: 'full',
    },
    {
        path: `:${ROUTE_PARAMS.orgId}`,
        canActivate: [activateForCloudAdminOrMember],
        data: { isNavigationHidden: true, title: DC_APP_TITLE } as RouteData,
        children: [
            {
                path: `${ROUTE_PATH.welcome}`,
                canActivate: [AccessControlService],
                loadChildren: () => import('./pages/welcome-page/welcome-page.component'),
            },
            {
                path: `${ROUTE_PATH.farewell}`,
                canActivate: [AccessControlService],
                loadChildren: () => import('./pages/farewell-page/farewell-page.component'),
            },
            {
                path: `${ROUTE_PATH.dashboard}`,
                canActivate: [AccessControlService],
                loadChildren: () => import('./pages/dashboards-page/dashboards-page.component'),
            },
            {
                path: `${ROUTE_PATH.inactivityTimer}/:${ROUTE_PARAMS.machineId}`,
                canActivate: [AccessControlService],
                loadChildren: () => import('./pages/inactivity-timer-page/inactivity-timer-page.component'),
            },
            {
                path: `${ROUTE_PATH.notifications}`,
                canActivate: [AccessControlService],
                loadChildren: () => import('./pages/notifications-page/notification-settings-page.component'),
                data: {
                    title: 'NOTIFICATIONS_SETTINGS.TITLE',
                    isNavigationHidden: true,
                    isSecondLevel: true,
                    breadcrumbs: [
                        {
                            text: DC_APP_TITLE,
                            routerLink: [ROUTE_PARAMS.orgId],
                        },
                        {
                            text: 'NOTIFICATIONS_SETTINGS.TITLE',
                        },
                    ],
                } as RouteData,
            },
            {
                path: `${ROUTE_PATH.settings}`,
                canActivate: [activateForCloudAdminOrMember],
                loadChildren: () => import('./pages/settings-page/settings-page.component'),
                data: {
                    title: 'DC.SETTINGS.TITLE',
                    isNavigationHidden: true,
                    isSecondLevel: true,
                    breadcrumbs: [
                        {
                            text: DC_APP_TITLE,
                            routerLink: [ROUTE_PARAMS.orgId],
                        },
                        {
                            text: 'DC.SETTINGS.TITLE',
                        },
                    ],
                } as RouteData,
            },
        ],
    },
];
