<nav
    class="hdmui-subnav navigation"
    mat-tab-nav-bar
    mat-stretch-tabs="false"
    [tabPanel]="tabPanel"
    [ngClass]="{ 'hdmui-background-1': isSecondLevel }"
>
    @for (link of links; track link) {
        <a mat-tab-link [routerLink]="link.path" routerLinkActive #rla="routerLinkActive" [active]="rla.isActive">
            {{ link.title | translate }}
        </a>
    }
</nav>
